<template>
  <div class="mainPage">
    <!-- 查询 -->
    <div class="goods_Evalaute">
      <el-form inline :model="serchInfo" ref="infoRef">
        <el-form-item label="商品分类">
          <el-select v-model="serchInfo.category_id_1" clearable>
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="(item, index) in categoryList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 评价商品 -->
        <el-form-item label="评价商品">
          <el-input
            v-model="serchInfo.goods_name"
            clearable
            maxlength="20"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <!-- 评价等级 -->
        <el-form-item label="评价等级">
          <el-select v-model="serchInfo.evaluate_level" clearable>
            <el-option label="好评" value="1"></el-option>
            <el-option label="中评" value="3"></el-option>
            <el-option label="差评" value="5"></el-option>
          </el-select>
        </el-form-item>
        <!-- 评价时间 -->
        <el-form-item label="评价时间">
          <el-date-picker
            v-model="serchInfo.create_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <!-- 评价关键字 -->
        <el-form-item label="评价关键字">
          <el-input
            v-model="serchInfo.evaluate_content"
            clearable
            placeholder="请输入关键字"
            maxlength="10"
          ></el-input>
        </el-form-item>

        <!-- 是否展示 -->
        <el-form-item label="是否展示">
          <el-select v-model="serchInfo.is_show" clearable>
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <div style="display: flex;align-items: center;">
            <el-checkbox-group
              v-model="serchInfo.is_image"
              style="margin-right: 5px;"
            >
              <el-checkbox label="带图评价" name="is_image"></el-checkbox>
            </el-checkbox-group>
            <el-checkbox-group
              v-model="serchInfo.is_self_evaluate"
              style="margin-right: 5px;"
            >
              <el-checkbox
                label="自主评价"
                name="is_self_evaluate"
              ></el-checkbox>
            </el-checkbox-group>
            <el-checkbox-group
              v-model="serchInfo.is_system_evaluate"
              style="margin-right: 5px;"
            >
              <el-checkbox
                label="默认评价"
                name="is_system_evaluate"
              ></el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>

        <!-- 按钮 -->
        <el-form-item>
          <el-button type="primary" @click="serchDetails">查询</el-button>
          <el-button
            v-if="
              module_id_array.includes('182') || module_id_array.includes('999')
            "
            @click="exportData"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
      <div class="main_info">
        <div class="divBox">
          <span>评价条数：</span>
          <span style="color: #FF514B;font-weight: 600;">
            {{ info.all_evaluate_count }}
          </span>
          <span>条</span>
        </div>
        <div class="divBox">
          <span>好评：</span>
          <span style="color: #FF514B;font-weight: 600;">
            {{ info.good_evaluate_count }}
          </span>
          <span>条</span>
        </div>
        <div class="divBox">
          <span>中评：</span>
          <span style="color: #FF514B;font-weight: 600;">
            {{ info.mid_evaluate_count }}
          </span>
          <span>条</span>
        </div>
        <div class="divBox">
          <span>差评：</span>
          <span style="color: #FF514B;font-weight: 600;">
            {{ info.bad_evaluate_count }}
          </span>
          <span>条</span>
        </div>
        <div class="divBox">
          <span>平均评分：</span>
          <span style="color: #FF514B;font-weight: 600;">{{
            info.evaluate
          }}</span>
          <span>分</span>
        </div>
        <div class="divBox">
          <span>好评率：</span>
          <span style="color: #FF514B;font-weight: 600;">
            {{ info.good_percent }}
          </span>
          <span>%</span>
        </div>
      </div>
      <div class="table-box">
        <div class="head_title">
          <div style="width: 20%;">评价内容</div>
          <div style="width: 23%;">评价分数</div>
          <div style="width: 19%;">服务评价</div>
          <div style="width: 23%;">用户</div>
          <div style="width: 15%;">操作</div>
        </div>
        <div class="content">
          <div
            class="content_item"
            v-for="(item, index) in list"
            :key="index"
            v-if="list.length"
          >
            <div class="desc">
              <div class="one">
                <div style="margin-right: 6px;">评价商品:</div>
                <img
                  :src="item.picture_str"
                  alt=""
                  style="margin-right: 4px;"
                />
                <div class="text" @click="toGoodsDetails(item.goods_id)">
                  {{ item.goods_name }}
                </div>
              </div>
              <div class="two">
                <div>商品规格：{{ item.sku_name }}</div>
              </div>
              <div class="three">
                <div>订单号：</div>
                <div
                  @click="toOrderDetail(item.order_id)"
                  style="color: #4974F5;cursor: pointer;"
                >
                  {{ item.order_sn }}
                </div>
              </div>
              <div class="four">
                <div>评价时间：</div>
                <div>{{ item.create_time }}</div>
              </div>
              <div class="five">
                <div style="margin-right: 9px;">
                  是否展示：{{ item.is_show ? "是" : "否" }}
                </div>
                <!-- <img
                  v-if="item.is_anonymous"
                  src="../../assets/home/eya.png"
                  alt=""
                /> -->
                <img
                  v-if="item.is_anonymous"
                  src="../../assets/home/noeya.png"
                  alt=""
                />
              </div>
            </div>
            <div class="tab_info">
              <div class="info_one">
                <div>
                  {{ item.evaluate_content }}
                </div>
                <div class="vid_img_box">
                  <div
                    style="width: 24px; height: 24px;margin-right: 8px;border-radius: 4px;position: relative;cursor: pointer;"
                    @click="playVid(item)"
                    v-if="item.evaluate_video_image_str"
                  >
                    <el-image
                      style="width: 24px; height: 24px;margin-right: 8px;border-radius: 4px;"
                      :src="item.evaluate_video_image_str"
                    >
                    </el-image>
                    <i
                      class="el-icon-video-play"
                      style="position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);display: inline-block;font-size: 16px;"
                    ></i>
                  </div>
                  <el-image
                    style="width: 24px; height: 24px;margin-right: 8px;border-radius: 4px;margin-bottom: 8px;"
                    :src="e"
                    v-for="(e, indexe) in item.evaluate_image_str"
                    :key="indexe"
                    :preview-src-list="item.evaluate_image_str"
                  >
                  </el-image>
                </div>
              </div>
              <div class="info_two">
                <el-rate
                  v-model="item.goods_evaluate"
                  disabled
                  text-color="#f2cb51"
                >
                </el-rate>
              </div>
              <div class="info_three">
                <div>商家服务</div>
                <div>
                  <el-rate
                    v-model="item.shop_evaluate"
                    disabled
                    text-color="#f2cb51"
                  >
                  </el-rate>
                </div>
                <div>物流时效</div>
                <div>
                  <el-rate
                    v-model="item.logistics_evaluate"
                    disabled
                    text-color="#f2cb51"
                  >
                  </el-rate>
                </div>
              </div>
              <div class="info_four">
                {{ item.nick_name }}（{{ item.phone }}）
              </div>
              <div
                class="info_five"
                @click="changeTop(item.item_id, item.is_top)"
              >
                {{ item.is_top ? "取消置顶" : "置顶" }}
              </div>
            </div>
          </div>
          <div class="content_item" v-if="!list.length" style="width: 100%;min-height: 60px;display: flex;align-items: center;justify-content: center;color: #909399;font-size: 12px;">
            暂无数据
          </div>
        </div>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        style="margin: 30px 0 40px 0"
      ></el-pagination>
    </div>
    <div
      style="width: 100%;height: 100%;position: fixed;top: 0;left: 0;z-index: 999999;background: rgba(0, 0, 0, 0.1);"
      v-if="vidInfo.show"
    >
      <div
        style="position: absolute;top:50%;left: 50%;transform: translate(-50%,-50%);"
      >
        <i
          class="el-icon-error"
          style="font-size: 30px;float: right;margin-bottom: 15px;z-index: 999;display: inline-block;cursor: pointer;"
          @click="closeVid"
        ></i>
        <VideoPlay
          :videoUrl="vidInfo.evaluate_video_str"
          :videoCover="vidInfo.evaluate_video_image_str"
          :width="800"
          :height="450"
          :controls="true"
          :loop="false"
          :muted="false"
          preload="auto"
          :showPlay="true"
          :playWidth="96"
          zoom="cotain"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VideoPlay from "@/components/common/VideoPlay";
export default {
  components: {
    VideoPlay,
  },
  data() {
    return {
      goods_id: this.$route.query.goods_id || "",
      total: 0,
      page: 1,
      pageNumber: 10,
      create_time: [],
      serchInfo: {
        category_id_1: "",
        goods_name: this.$route.query.goods_name || "",
        evaluate_level: "",
        evaluate_content: "",
        is_show: "",
        is_image: "",
        is_self_evaluate: "",
        is_system_evaluate: "",
        create_time: "",
      },
      info: {},
      list: [],
      categoryList: [],
      module_id_array: window.utils.storage.getter("module_id_array") || [],
      vidInfo: {
        show: false,
        evaluate_video_str: "",
        evaluate_video_image_str: "",
      },
    };
  },
  mounted() {
    this.getInfo();
  },
  created() {
    this.getCategoryList();
  },
  methods: {
    serchDetails() {
      this.page = 1;
      this.getInfo();
    },
    getInfo() {
      const data = {
        goods_id: this.goods_id,
        page: this.page,
        pageNumber: this.pageNumber,
        category_id_1: this.serchInfo.category_id_1,
        goods_name: this.serchInfo.goods_name,
        create_time_start: "",
        create_time_end: "",
        evaluate_level: this.serchInfo.evaluate_level,
        evaluate_content: this.serchInfo.evaluate_content,
        is_image: this.serchInfo.is_image ? 1 : 0,
        is_self_evaluate: this.serchInfo.is_self_evaluate ? 1 : 0,
        is_system_evaluate: this.serchInfo.is_system_evaluate ? 1 : 0,
        is_show: this.serchInfo.is_show,
      };
      if (this.serchInfo.create_time && this.serchInfo.create_time[0]) {
        data.create_time_start = this.dayjs(
          this.serchInfo.create_time[0]
        ).format("YYYY-MM-DD");
        data.create_time_end = this.dayjs(this.serchInfo.create_time[1]).format(
          "YYYY-MM-DD"
        );
      }
      console.log("123", data);
      this.axios
        .post("/store/Shopevaluate/getUserOrderEvaluateList", data)
        .then((res) => {
          this.goods_id = "";
          if (this.page == 1) {
            this.info = res.data.info;
          }
          this.total = res.data.total;
          const list = res.data.list;
          list.map((r) => {
            r.goods_evaluate = Number(r.goods_evaluate);
            r.logistics_evaluate = Number(r.goods_evaluate);
            r.shop_evaluate = Number(r.goods_evaluate);
            r.create_time = this.dayjs(r.create_time * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            r.phone = r.phone.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2");
            return r;
          });
          this.list = list;
          console.log("list", this.list);
        });
    },
    playVid(item) {
      this.vidInfo.show = true;
      this.vidInfo.evaluate_video_str = item.evaluate_video_str;
      this.vidInfo.evaluate_video_image_str = item.evaluate_video_image_str;
    },
    closeVid() {
      this.vidInfo = {
        show: false,
        evaluate_video_str: "",
        evaluate_video_image_str: "",
      };
    },
    // 改变置顶
    changeTop(item_id, is_top) {
      const data = {
        item_id: item_id,
        is_top: is_top ? 0 : 1,
      };
      this.axios
        .post("/store/Shopevaluate/editUserOrderEvaluateItemTop", data)
        .then((res) => {
          this.getInfo();
        });
    },
    // 导出数据
    exportData() {
      const data = {
        goods_id: this.goods_id,
        page: this.page,
        pageNumber: this.pageNumber,
        category_id_1: this.serchInfo.category_id_1,
        goods_name: this.serchInfo.goods_name,
        create_time_start: this.serchInfo.create_time_start || "",
        create_time_end: this.serchInfo.create_time_end || "",
        evaluate_level: this.serchInfo.evaluate_level,
        evaluate_content: this.serchInfo.evaluate_content,
        is_image: this.serchInfo.is_image ? 1 : 0,
        is_self_evaluate: this.serchInfo.is_self_evaluate ? 1 : 0,
        is_system_evaluate: this.serchInfo.is_system_evaluate ? 1 : 0,
        is_show: this.serchInfo.is_show,
      };
      if (this.serchInfo.create_time && this.serchInfo.create_time[0]) {
        data.create_time_start = this.dayjs(
          this.serchInfo.create_time[0]
        ).format("YYYY-MM-DD");
        data.create_time_end = this.dayjs(this.serchInfo.create_time[1]).format(
          "YYYY-MM-DD"
        );
      }
      this.axios
        .post("/store/Shopevaluate/exportUserOrderEvaluateList")
        .then((res) => {
          let host =
            document.location.protocol +
            "//" +
            window.location.host +
            res.data.url;

          window.open(host);
        });
    },
    // 去商品信息
    toGoodsDetails(goods_id) {
      this.$router.push({
        path: "/goods/checkGood",
        query: {
          goods_id,
        },
      });
    },
    toOrderDetail(order_id) {
      this.$router.push({ path: "/order/orderDetail", query: { order_id } });
    },
    // 获取商品分类
    getCategoryList() {
      this.axios.post("/store/Shopgoods/getCategoryList").then((res) => {
        let list = res.data.list;
        let arr = [];
        list.forEach((ele) => {
          arr.push({
            value: ele.category_id,
            label: ele.category_name,
          });
        });
        this.categoryList = arr;
      });
    },
    handleSizeChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getInfo();
    },
    handleCurrentChange(size) {
      this.page = size;
      this.getInfo();
    },
  },
};
</script>
<style lang="scss" scoped>
.mainPage {
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  overflow: auto; /* 溢出需要可滚动 */
  scrollbar-width: none; /* Firefox隐藏滚动条 */
  -ms-overflow-style: none; /* Internet Explorer 10+隐藏滚动条 */
  ::-webkit-scrollbar {
    width: 0; /* Safari,Chrome 隐藏滚动条 */
    height: 0; /* Safari,Chrome 隐藏滚动条 */
    display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
  }

  .goods_Evalaute {
    height: 100%;
    overflow-y: scroll;
  }
  .main_info {
    display: flex;
    align-items: center;
    font-size: 14px;
    .divBox {
      display: flex;
      align-items: center;
      margin-right: 40px;
      span {
        height: 20px;
        display: flex;
        align-items: center;
      }
    }
  }
  .table-box {
    width: 100%;
    box-sizing: border-box;
    border-color: #c9cbd6;
    .head_title {
      height: 50px;
      background: #ebebfd;
      border-radius: 3px 3px 0px 0px;
      border-bottom: 1px solid #c9cbd6;
      display: flex;
      align-items: center;
      div {
        padding-left: 16px;
      }
    }
    .content {
      width: 100%;
      .content_item {
        width: 100%;
        .desc {
          width: 100%;
          height: 36px;
          display: flex;
          align-items: center;
          background: #f5f5f5;
          .one {
            width: calc(20% - 12px);
            padding-left: 16px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            word-break: keep-all;

            img {
              width: 24px;
              height: 24px;
              background: #cfcfcf;
              border-radius: 4px;
            }
            .text {
              word-break: keep-all;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #4974f5;
              cursor: pointer;
            }
          }
          .two {
            width: calc(23% - 41px);
            padding-left: 16px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
          }
          .three {
            width: calc(19% + 19px);
            padding-left: 16px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
          }
          .four {
            width: calc(23% + 22px);
            padding-left: 16px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
          }
          .five {
            width: auto;
            padding-left: 16px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
        .tab_info {
          display: flex;
          font-size: 14px;
          line-height: 22px;
          min-height: 115px;
          border-bottom: 1px solid #c9cbd6;
          .info_one {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 20%;
            padding: 0 16px;
            box-sizing: border-box;
            border-right: 1px solid #e8e9f0;
            .vid_img_box {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
            }
          }
          .info_two {
            display: flex;
            align-items: center;
            width: 23%;
            padding-left: 16px;
            box-sizing: border-box;
            border-right: 1px solid #e8e9f0;
          }
          .info_three {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 19%;
            padding-left: 16px;
            box-sizing: border-box;
            border-right: 1px solid #e8e9f0;
          }
          .info_four {
            display: flex;
            align-items: center;
            width: 23%;
            padding-left: 16px;
            box-sizing: border-box;
            border-right: 1px solid #e8e9f0;
          }
          .info_five {
            display: flex;
            align-items: center;
            width: 15%;
            padding-left: 16px;
            box-sizing: border-box;
            color: #4974f5;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
